import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", {
    attrs: {
      "title": "Add Truck"
    }
  }, [_c("text-input", {
    key: "code",
    attrs: {
      "label": "Truck Code",
      "placeholder": "Type Code",
      "span": 24,
      "rules": "required|max:17"
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "label": "Description",
      "placeholder": "Type Description",
      "span": 24,
      "rules": "max:35"
    }
  }), _c("switch-input", {
    key: "zeroWeightAllowed",
    attrs: {
      "label": "Zero Weight Allowed",
      "span": 8,
      "value": false
    }
  }), _c("select-input", {
    key: "truckTypeId",
    attrs: {
      "label": "Truck Type",
      "placeholder": "Select Truck Type",
      "reference": "grower.truck-types",
      "source": "id",
      "rules": "required",
      "source-label": "name",
      "span": 16
    }
  }), _c("text-input", {
    key: "hiddenTare",
    attrs: {
      "hidden-field": true,
      "span": 8
    }
  }), _c("text-input", {
    key: "tare",
    attrs: {
      "label": "Truck tare",
      "placeholder": "Type Truck Tare",
      "span": 16,
      "rules": "number"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateTrucks",
  inject: ["crud"],
  created() {
    this.storeValue("zeroWeightAllowed", false);
  },
  methods: {
    storeValue(resourceKey, value) {
      this.createForm = {
        ...this.createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateTrucks = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-truck"
  }, [_c("resource", {
    attrs: {
      "name": "grower.truck-types",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.trucks",
      "api-url": _vm.apiUrl,
      "create": _vm.CreateTrucks,
      "redirect-route": "/watties-grower/trucks"
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateTrucks",
  data() {
    return {
      CreateTrucks,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
